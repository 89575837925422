import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fcfCols', standalone: true
})
export class FcfColsPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let key = value;
    switch (value) {
      case 'make':
        key = "Make and Model";
        break;
      case "yearOfRegistration":
        key = "Year";
        break;
      case "engineType":
        key = "Engine Type";
        break;
      case "vrtEuro":
        key = "VRT";
        break;
      case "shippingEur":
        key = "Est. shipping";
        break;
      case "priceEstExVat":
        key = "Est. sales price IE";
        break;
      case "priceEurExVat":
        key = "Est. purchase price UK - Net VAT";
        break;
      case "vat":
        key = "VAT";
        break;
      case "houseMiscCharge":
        key = "House/misc charge";
        break;
      case "profit":
        key = "Profit";
        break;
      case "vatAdjustment":
        key = "VAT adjustment &/or Delivery";
        break;
      case "customsDuty":
        key = "Customs Duty";
        break;
      case "totalCosts":
        key = "Total Cost to Import";
        break;
      case "roi":
        key = "ROI";
        break;
      case "kms":
        key = "Mileage";
        break;
    }
    return key;
  }

}
