import { DecimalPipe} from "@angular/common";
import {Component, Inject} from "@angular/core";
import { MatAnchor } from "@angular/material/button";
import {
  MatDialogActions,
  MatDialog, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiService } from "../../../services/api.service";
import {CcqTotalsComponent} from "../../../components/tables/ccq-totals/ccq-totals.component";

@Component({
  selector: "app-quote",
  standalone: true,
  imports: [DecimalPipe, MatAnchor, MatDialogActions, CcqTotalsComponent, MatDialogTitle, MatDialogContent],
  templateUrl: "./quote.component.html",
  styleUrl: "./quote.component.scss",
})
export class QuoteComponent {

  constructor(
    protected dialog: MatDialog,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Add service fee
    data.serviceFee = this.data.total * 0.02;
    // Add to total
    this.data.total += this.data.serviceFee;
  }

  async submitQuote() {
    await this.apiService.submitQuote(this.data);
    this.snackBar.open("Thank you for submititing your request!", undefined, {
      panelClass: "snack-ok",
      duration: 5000,
    });
    this.dialog.closeAll();
  }
}
