import { Component, Inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatOption } from "@angular/material/core";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-vat-adjustment-dialog",
  standalone: true,
  imports: [
    MatButton,
    MatDialogContent,
    MatDialogActions,
    MatDialogTitle,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatRadioModule,
    CommonModule,
  ],
  templateUrl: "./vat-adjustment-dialog.component.html",
  styleUrls: ["./vat-adjustment-dialog.component.scss"],
})
export class VatAdjustmentDialogComponent {
  vatAdjustment: number = 0; // Fixed VAT adjustment value
  vatPercentage: number = 0; // VAT percentage value
  adjustmentType: "fixed" | "percentage" = "fixed"; // Default adjustment type

  private readonly GENERAL_VAT_KEY = "vatAdjustment_general";
  private readonly GENERAL_PERCENTAGE_KEY = "vatAdjustment_general_percentage";

  constructor(
    private dialogRef: MatDialogRef<VatAdjustmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { vehicleId?: string; isGeneral?: boolean; priceEurExVat?: number }
  ) {
    // Initialize VAT adjustment values from localStorage
    if (data.isGeneral) {
      this.vatAdjustment = this.getLocalStorageValue(this.GENERAL_VAT_KEY, 0);
      this.vatPercentage = this.getLocalStorageValue(this.GENERAL_PERCENTAGE_KEY, 0);
    } else if (data.vehicleId) {
      this.vatAdjustment = this.getLocalStorageValue(`vatAdjustment_${data.vehicleId}`, 0);
      this.vatPercentage = this.getLocalStorageValue(
        `vatAdjustment_${data.vehicleId}_percentage`,
        0
      );
    }
  }

  /**
   * Save VAT adjustment to localStorage.
   * @param forAll Indicates whether the adjustment applies to all vehicles.
   */
  saveAdjustment(forAll: boolean): void {
    if (forAll) {
      // Save general VAT adjustment
      this.setLocalStorageValue(this.GENERAL_VAT_KEY, this.vatAdjustment);
      this.setLocalStorageValue(this.GENERAL_PERCENTAGE_KEY, this.vatPercentage);
    } else if (this.data.vehicleId) {
      // Save vehicle-specific VAT adjustment
      this.setLocalStorageValue(`vatAdjustment_${this.data.vehicleId}`, this.vatAdjustment);
      this.setLocalStorageValue(
        `vatAdjustment_${this.data.vehicleId}_percentage`,
        this.vatPercentage
      );
    }

    // Close the dialog and pass adjustment details
    this.dialogRef.close({
      adjustmentType: this.adjustmentType,
      adjustmentValue: this.adjustmentType === "fixed" ? this.vatAdjustment : this.vatPercentage,
      appliedTo: forAll ? "all" : "vehicle",
    });
  }

  /**
   * Close the dialog without saving.
   */
  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Retrieve a value from localStorage or return a default if not found.
   * @param key The localStorage key.
   * @param defaultValue The default value if the key does not exist.
   * @returns The stored value or the default value.
   */
  private getLocalStorageValue(key: string, defaultValue: number): number {
    return Number(localStorage.getItem(key)) || defaultValue;
  }

  /**
   * Save a value to localStorage.
   * @param key The localStorage key.
   * @param value The value to save.
   */
  private setLocalStorageValue(key: string, value: number): void {
    localStorage.setItem(key, value.toString());
  }
}
