@if (data.bugs.submitted) {
  <h2 mat-dialog-title>Thank you!</h2>
} @else {
  <h2 mat-dialog-title>Report an issue</h2>
}
<mat-dialog-content>
  @if (!data.bugs.submitted) {
  <p>We are sorry that you experienced an issue today. Please let us know the nature of the issue you faced. Please provide as much information as possible and if possible provide screen grabs for further context.</p>
  <form [formGroup]="form">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Details</mat-label>
      <textarea matInput placeholder="Please describe the issue you faced" formControlName="details"></textarea>
    </mat-form-field>
  </form>
  } @else {
    <p>Thank you for submitting this bug report. As you know things go wrong but it’s what we all do when it happens that
      counts. We take any issues with our reports very seriously so we will be investigating and resolving this issue
      immediately. The next steps are as follows.</p>
    <ol>
      <li>We will contact you via phone to discuss the matter further.</li>
      <li>We verify and correct where necessary the information and email this to you immediately.</li>
      <li>We will investigate how the error occurred.</li>
      <li>We will make amendments to our tech if required to ensure that this error does not happen again.</li>
    </ol>
    <p>Thank you for helping to make TheCarSite better. By working together on matters such as this we improve our overall
      offering for the benefit of all. It’s easy to do nothing but you didn’t. You took action. You rock!</p>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="data.bugs.close()">Cancel</button>
  <span class="spacer"></span>
  @if (!data.bugs.submitted) {
    <button mat-raised-button color="primary" (click)="data.bugs.submit(this.form)" [disabled]="!form.get('details')?.value">Submit</button>
  } @else {
    <button mat-raised-button color="primary" (click)="data.bugs.close()">Close</button>
  }
</mat-dialog-actions>
