import { Component, Inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: "app-house-misc-charge-dialog",
  standalone: true,
  imports: [
    MatButton,
    MatDialogContent,
    MatDialogActions,
    MatDialogTitle,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  templateUrl: "./house-misc-charge-dialog.component.html",
  styleUrls: ["./house-misc-charge-dialog.component.scss"],
})
export class HouseMiscChargeDialogComponent {
  miscCharge: number = 0; // Initialize house/miscellaneous charge value

  constructor(
    private dialogRef: MatDialogRef<HouseMiscChargeDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { vehicleId?: string; isGeneral?: boolean; make: string; model: string }
  ) {
    // Load the initial value for the charge based on type (general, per car, or per make/model)
    if (data.isGeneral) {
      this.miscCharge = Number(localStorage.getItem("houseMiscCharge_general") || "0");
    } else if (data.vehicleId) {
      this.miscCharge = Number(localStorage.getItem(`houseMiscCharge_${data.vehicleId}`) || "0");
    } else if (data.make && data.model) {
      this.miscCharge = Number(
        localStorage.getItem(`houseMiscCharge_${data.make}_${data.model}`) || "0"
      );
    }
  }

  /**
   * Save the house/miscellaneous charge for a specific vehicle.
   */
  saveCharge(forAll: boolean): void {
    if (forAll) {
      // Save the general charge
      localStorage.setItem("houseMiscCharge_general", this.miscCharge.toString());
      this.dialogRef.close({
        type: "general",
        miscCharge: this.miscCharge,
      });
    } else if (this.data.make && this.data.model) {
      // Save the charge for a specific make and model
      localStorage.setItem(
        `houseMiscCharge_${this.data.make}_${this.data.model}`,
        this.miscCharge.toString()
      );
      this.dialogRef.close({
        type: "makeAndModel",
        make: this.data.make,
        model: this.data.model,
        miscCharge: this.miscCharge,
      });
    } else if (this.data.vehicleId) {
      // Save the charge for a specific vehicle
      localStorage.setItem(`houseMiscCharge_${this.data.vehicleId}`, this.miscCharge.toString());
      this.dialogRef.close({
        type: "vehicle",
        vehicleId: this.data.vehicleId,
        miscCharge: this.miscCharge,
      });
    } else {
      console.error("Invalid condition for saving house/misc charge.");
    }
  }

  /**
   * Close the dialog without saving any changes.
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
