import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {GenericDialogComponent} from "../components/dialogs/generic-dialog/generic-dialog.component";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  // openDialog(component: any, data?: any): void {
  //   this.dialog.open(GenericDialogComponent, {
  //     width: data.maxWidth,
  //     panelClass: "custom-mat-dialog",
  //     data: { component, ...data },
  //   });
  // }

  openDialog(component: any | null, data: any): MatDialogRef<GenericDialogComponent> {
    return this.dialog.open(GenericDialogComponent, {
      width: data.maxWidth || "400px",
      panelClass: "custom-mat-dialog",
      data: { component, ...data },
    });
  }
}
