import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {BugService} from "../../../services/bug.service";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'app-bug',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel
  ],
  templateUrl: './bug.component.html',
  styleUrl: './bug.component.scss'
})
export class BugComponent {
  form: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { service: string, bugs: BugService}) {
    this.form = new FormGroup({
      'issue': new FormControl(''),
      'details': new FormControl('')
    });
  }
}
