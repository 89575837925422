<div class="total-table">
  <div class="table single">
    <div class="table-segment">
      @for (tb of totalSchema | keyvalue: origOrder; track tb.key) {
          <div class="row">
            <div class="property">{{ tb.value }}</div>
            <div class="value">
              @if(totalBreakdown[tb.key] !== undefined) {
                {{ totalBreakdown[tb.key] | currency: "EUR":"symbol":"1.0-0" : "en" }}
              }
            </div>
          </div>
      }
    </div>
  </div>
</div>
