<div class="dialog-header">
  <h4 class="dialog-title">{{ data.headerTitle }}</h4>
  <button class="dialog-close" (click)="closeDialog()"><mat-icon>close</mat-icon></button>
</div>
<!-- <div class="dialog-body">
  <ng-template #dynamicComponentContainer></ng-template>
</div> -->
<div class="dialog-body">
  <div [innerHTML]="data.content"></div>
</div>
