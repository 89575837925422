<!--<h2 mat-dialog-title>{{ data.make }} {{ data.model }}</h2>-->
<mat-dialog-content>
  <mat-accordion [multi]="true">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <div class="expansion-header-content">
          <h3>Vehicle details</h3>
        </div>
      </mat-expansion-panel-header>
      <app-vehicle-data [data]="data"></app-vehicle-data>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <div class="expansion-header-content">
          <h3>VRT details</h3>
        </div>
      </mat-expansion-panel-header>
      <app-vrt-data [data]="data"></app-vrt-data>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <a
    [routerLink]="['/home/ccq',{'url': data.registrationNumber ?? FcfService.getSource(data).url}]"
    (click)="dialog.closeAll()"
    mat-raised-button
    >Get an import quote on this vehicle</a
  >
  <a
    [routerLink]="['/home/cyc',{'url': data.registrationNumber ?? FcfService.getSource(data).url}]"
    (click)="dialog.closeAll()"
    mat-raised-button
    >Do a history check on this vehicle</a
  >
  <a
    [href]="FcfService.getSource(data).url"
    target="_blank"
    rel="noopener noreferrer"
    mat-raised-button
    >Open this ad on {{ FcfService.getSource(data).source }}</a
  >
  <a mat-raised-button href="javascript:void(0)" (click)="openVatAdjustmentDialog(data.id,data.priceEurExVat)">
    Vat Adjustment
  </a>

  <a mat-raised-button href="javascript:void(0)" (click)="openHouseMiscChargeDialog(data.id, data.make, data.model)">
    House/Misc Charge
  </a>

  @if (userService.admin) {
  <button mat-raised-button color="primary" [matMenuTriggerFor]="adminMenu">Admin</button>
  <mat-menu #adminMenu>
    <button mat-menu-item (click)="showEquivs()">Show equivalent IRE cars</button>
    <button mat-menu-item (click)="maskEntry(data)">Hide this entry</button>
  </mat-menu>
  }
</mat-dialog-actions>
