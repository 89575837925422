<h2 mat-dialog-title class="dialog-title">
  {{ data.isGeneral ? 'Adjust General House/Misc Charge' : 'Adjust House/Misc Charge for Vehicle' }}
</h2>
<mat-dialog-content>
  <div class="dialog-container">
    <mat-form-field appearance="fill" class="misc-charge-input">
      <mat-label>Enter House/Misc Charge</mat-label>
      <input
        matInput
        [(ngModel)]="miscCharge"
        type="number"
        placeholder="Enter House/Misc Charge"
      />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="button-row">
  <button mat-raised-button (click)="saveCharge(false)">
    Set for Vehicle
  </button>
  <button mat-raised-button (click)="saveCharge(false)">
    Set for {{ data.make }} {{ data.model }}
  </button>
  <button mat-raised-button (click)="saveCharge(true)">
    Set for All Vehicles
  </button>
  <button mat-raised-button (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>
