import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogContent,
  MatDialogModule,
} from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { RouterLink } from "@angular/router";
import { EquivCarsDialog } from "../equiv-cars/equiv-cars";
import { VehicleDataComponent } from "../../tables/vehicle-data/vehicle-data.component";
import { VrtDataComponent } from "../../tables/vrt-data/vrt-data.component";
import { ApiService } from "../../../services/api.service";
import { UserService } from "../../../services/user.service";
import { FcfService } from "../../../services/fcf.service";
import { MatAccordion, MatExpansionModule } from "@angular/material/expansion";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { GenericDialogComponent } from "../generic-dialog/generic-dialog.component";
import { DomSanitizer } from "@angular/platform-browser";
import { DialogService } from "../../../services/dialog.service";
import { VatAdjustmentService } from "../../../services/vat-adjustment.service";
import { VatAdjustmentDialogComponent } from "../vat-adjustment-dialog/vat-adjustment-dialog.component";
import { HouseMiscChargeDialogComponent } from "../house-misc-charge-dialog/house-misc-charge-dialog.component";
import { HouseMiscChargeService } from "../../../services/house-misc-charge.service";

@Component({
  selector: "dialog-car-details",
  templateUrl: "../dialog-car-details/dialog-car-details.html",
  styleUrl: "../dialog-car-details/dialog-car-details.scss",
  standalone: true,
  imports: [
    MatDialogContent,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    RouterLink,
    VehicleDataComponent,
    VrtDataComponent,
    MatAccordion,
    MatExpansionModule,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
  ],
})
export class CarDetailsDialog {
  protected readonly Number = Number;

  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private apiService: ApiService,
    protected dialog: MatDialog,
    protected userService: UserService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private vatAdjustmentService: VatAdjustmentService,
    private houseMiscChargeService: HouseMiscChargeService
  ) {}

  async showEquivs() {
    this.dialog.open(EquivCarsDialog, {
      data: {
        data: this.data,
      },
    });
  }

  async maskEntry(data: any) {
    await this.apiService.maskEntry(data.id);
    data.masked = data.masked ? null : "masked";
    this.dialog.closeAll();
  }

  openVatAdjustmentDialog(vehicleId?: string, priceEurExVat?: any): void {
    // Open the VatAdjustmentDialogComponent dialog
    const dialogRef = this.dialog.open(VatAdjustmentDialogComponent, {
      width: "400px", // Fixed dialog width
      data: {
        vehicleId: vehicleId || null, // Pass vehicleId if adjusting for a specific vehicle, or null for general VAT adjustment
        priceEurExVat: priceEurExVat,
        isGeneral: !vehicleId, // Determine if this is a general adjustment based on absence of vehicleId
      },
    });

    // Handle the dialog close event
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Trigger data refresh in FcfHomeComponent
        this.vatAdjustmentService.triggerDataRefresh();
      }
    });
  }

  openHouseMiscChargeDialog(vehicleId?: string, make?: string, model?: string): void {
    // Open a dialog for miscellaneous charges related to a house, passing optional vehicle ID
    const dialogRef = this.dialog.open(HouseMiscChargeDialogComponent, {
      width: "400px", // Set the width of the dialog
      data: {
        vehicleId: vehicleId || null, // If vehicleId is not provided, use null
        isGeneral: !vehicleId, // Determine if the adjustment is general (true if no vehicleId is provided)
        make,
        model,
      },
    });

    // Subscribe to the afterClosed observable to perform actions when the dialog is closed
    dialogRef.afterClosed().subscribe(result => {
      // Check if there was a result from the dialog
      if (result) {
        // If there's a result, trigger data refresh in FcfHomeComponent
        this.houseMiscChargeService.triggerDataRefresh();
      }
    });
  }

  protected readonly FcfService = FcfService;
}
