import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'source',
  standalone: true
})
export class SourcePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'bca':
        return 'BCA';
      case 'autotrader':
        return 'Autotrader.co.uk (trade sites only)';
      case 'manheim':
        return 'Manheim';
      case 'astonbarclay':
        return 'Aston Barclay';
      default:
        return value;
    }
  }

}
