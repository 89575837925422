import {Component, Input, OnInit} from "@angular/core";
import {CurrencyPipe, KeyValuePipe} from "@angular/common";

@Component({
  selector: "app-ccq-totals",
  standalone: true,
  imports: [CurrencyPipe, KeyValuePipe],
  templateUrl: "./ccq-totals.component.html",
  styleUrl: "./ccq-totals.component.scss",
})
export class CcqTotalsComponent implements  OnInit{
  protected totalSchema: { [key: string]: string } = {
    priceEur: "Vehicle Purchase Price (incl UK VAT)",
    lessVat: "Less UK VAT",
    vrtEuro: "VRT",
    customsDetails: "Customs Duty",
    vat: "IRE VAT",
    transportDetails: "Transport",
    serviceFee: "Service Fee (2%)",
    total: "Total",
  };
  ngOnInit(): void {
    if (!this.totalBreakdown['serviceFee']) {
      // Only show service fee if available. This is only relevant for Quotes
      delete this.totalSchema['serviceFee'];
    }
  }
  @Input() totalBreakdown!: any;
  protected origOrder(): number {
    return 0;
  }
}
