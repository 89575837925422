<h2 mat-dialog-title>Quote for <strong>{{data.vehicleDetails.registrationNumber}}</strong> - {{data.vehicleDetails.make}} {{data.vehicleDetails.model}}</h2>
<mat-dialog-content>
  <p class="disclaimer">
    This quote is subject to verification of all details provided. If adjustments need to be made,
    you will receive an amended quote, reflecting the most accurate estimate we can provide.
  </p>
  <p class="note">
    Currency conversion rate GBP/EUR
    {{ data.currencyRate | number: "1.2-2" : "en" }}
  </p>
  <app-ccq-totals [totalBreakdown]="data"></app-ccq-totals>
  <mat-dialog-actions class="dialog-actions" align="center">
    <a mat-raised-button color="primary" (click)="submitQuote()">Submit Request</a>
    <a mat-raised-button (click)="dialog.closeAll()">Cancel</a>
  </mat-dialog-actions>
</mat-dialog-content>
