import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,

} from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";
import { FeedbackService } from "../../../services/feedback.service";
import { CommerceService } from "../../../services/commerce.service";
import { BugService } from "../../../services/bug.service";
import { MatIconButton } from "@angular/material/button";

@Component({
  selector: "app-feedback",
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatIcon, MatIconButton],
  templateUrl: "./feedback.component.html",
  styleUrl: "./feedback.component.scss",
})
export class FeedbackComponent {
  constructor(
    private dialogRef: MatDialogRef<FeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: { service: string; vrm: string },
    public feedbackService: FeedbackService,
    protected bug: BugService
  ) {}

  protected readonly CommerceService = CommerceService;

  closeFeedback(): void {
    this.dialogRef.close();
  }
}
