import { Component, Inject, ViewChild, ViewContainerRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-dialog",
  standalone: true,
  imports: [MatIcon],
  templateUrl: "./generic-dialog.component.html",
  styleUrl: "./generic-dialog.component.scss",
})
export class GenericDialogComponent {
  @ViewChild("dynamicComponentContainer", { read: ViewContainerRef, static: true })
  container: ViewContainerRef | undefined;

  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.data.content = this.sanitizer.bypassSecurityTrustHtml(this.data.content);
  }

  headerTitle: string | undefined;

  ngOnInit() {
    // const comp = this.container?.createComponent(this.data.component);
    // (comp?.instance as any).data = this.data.data;

    if (this.data.component) {
      const comp = this.container?.createComponent(this.data.component);
      if (comp) {
        (comp.instance as any).data = this.data.data;
      } else if (this.data.content) {
        // Render raw HTML content if provided
        if (this.container) {
          this.container.element.nativeElement.innerHTML = this.data.content;
        }
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
