import { Inject, Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import { SESSION_STORAGE, StorageService} from "ngx-webstorage-service";
import {FormControl, FormGroup} from "@angular/forms";
import {SortDirection} from "@angular/material/sort";

export interface SortCriteria {
  active: string | null;
  direction: SortDirection;
}
export interface VRT {
  kms: string;
  bca_id: string | null; // Adjusted for null
  registrationNumber: string;
  id: number;
  make: string;
  model: string;
  transmissionType: string | number; // Adjusted to accept both
  numberOfDoors: string | number; // Adjusted to accept both
  engineType: string | number; // Adjusted to accept both
  bodyType: string | number; // Adjusted to accept both
  engineCapacity: number | null;
  statCode: string | null;
  mileage: number | null;
  yearOfRegistration: string | null;
  euroStatus: string | null;
  versionData: string | null;
  vrtEuro: string | null;
  omsp_current: string | null;
  attemptedVrt_ts: string | null;
  astonbarclay_id: string | null;
  manheim_id: string | null;
  autotrader_id: number | null;
  autotrader_seller_id: number | null;
  priceGBP: number | null;
  priceGBP_exvat: string | null;
  priceEst: string | null;
  priceEurExVat: string | null;
  vatAdjustment: number | null;
  priceEstExVat: string | null;
  shippingEur: string | null;
  houseMiscCharge: number | null;
  customsDuty: string | null;
  profit: string | null;
  exchange_rate: string;
  roi: string | null;
  totalCosts: string | null;
}
@Injectable({
  providedIn: "root",
})

export class FcfService {
  public total: number = 0;
  public makes: { [make: string]: string[] } = {};
  public bodyTypes: number[] = [];
  public loadingFacets: boolean = false;
  public loading: boolean = false;
  public colours: string[] = [];

  static maxCost: number = 250000;
  static maxKms: number = 400000;
  static minYear: number = 2000;
  static maxCo2: number = 400;
  static maxEngineSize: number = 5000;
  static maxProfit: number = 50000;
  static maxRoi: number = 2; // 200 %
  static maxYear: number = new Date().getFullYear();

  constructor(private apiService: ApiService, @Inject(SESSION_STORAGE) private storage: StorageService) {
  }

  /**
   * Get source (name and URL to ad)
   * @param element
   */
  static getSource(element: VRT | any): { url: string, source: string } {
    let source = {
      url: "", source: ""
    };
    if (element.autotrader_id) {
      source.url = `https://www.autotrader.co.uk/car-details/${element.autotrader_id}`;
      source.source = "Autotrader.co.uk";
    } else if (element.astonbarclay_id) {
      source.url = `https://catalogue.astonbarclay.net/details/catalogue/${element.astonbarclay_id}`;
      source.source = "AstonBarclay.net";
    } else if (element.manheim_id) {
      source.url = `https://www.manheim.co.uk/search?keywords=${element.registrationNumber}`;
      source.source = "Manheim.co.uk";
    } else if (element.bca_id) {
      source.url = `https://www.bca.co.uk/lot/${element.registrationNumber}`;
      source.source = "BCA.co.uk";
    } else if (element.source == "carsireland") {
      source.url = `https://www.carsireland.ie/${element.id}`;
      source.source = "Cars Ireland"
    } else if (element.source == "carzone") {
      source.source = "CarZone"
      source.url = `https://www.carzone.ie/used-cars/${element.make}/${element.model}/fpa/${element.id}`;
    } else if (element.source == "donedeal") {
      source.source = "DoneDeal"
      source.url = `https://www.donedeal.ie/cars-for-sale/a/${element.id}`;
    }
    return source;
  }

  public async get(pageSize: number, pageIndex: number, sortCriteria: SortCriteria, filters: {
    bodyTypes: number[],
    makes: string[],
    models: string[],
    kmsMin: number,
    kmsMax: number,
    co2Min: number,
    co2Max: number,
    engineSizeMin: number,
    engineSizeMax: number,
    engineTypes: number[],
    purchasePriceMin: number,
    purchasePriceMax: number,
    totalCostMin: number,
    totalCostMax: number,
    profitMin: number,
    profitMax: number,
    roiMin: number,
    roiMax: number,
    sellerTypes: string[],
    yearMin: number,
    yearMax: number,
    transmissionTypes: number[],
    numberOfDoors: number[],
    keyword: string,
    colours: string[],
    insuranceWriteOff: boolean,
    showNoIREPrice: boolean,
    showNoCosting: boolean,
  }): Promise<VRT[]> {
    const res = await this.apiService.getFcf(pageSize, pageIndex, sortCriteria.direction, sortCriteria.active, filters);
    if (res?.success) {
      this.total = res.data.rows;
      return res.data.fcf;
    }
    throw new Error(res.msg ?? "Could not retrieve FCF data");
  }

  initForm(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      makes: new FormControl([]),
      models: new FormControl({value: [], disabled: true}),
      kmsMin: new FormControl(0),
      kmsMax: new FormControl(FcfService.maxKms),
      engineSizeMin: new FormControl(0),
      engineSizeMax: new FormControl(FcfService.maxEngineSize),
      engineTypes: new FormControl([]),
      purchasePriceMin: new FormControl(0),
      purchasePriceMax: new FormControl(FcfService.maxCost),
      totalCostMin: new FormControl(0),
      totalCostMax: new FormControl(FcfService.maxCost),
      profitMin: new FormControl(0),
      profitMax: new FormControl(FcfService.maxProfit),
      roiMin: new FormControl(0),
      roiMax: new FormControl(FcfService.maxRoi),
      sellerTypes: new FormControl([]),
      yearMin: new FormControl(FcfService.minYear),
      yearMax: new FormControl(FcfService.maxYear),
      co2Min: new FormControl(0),
      co2Max: new FormControl(FcfService.maxCo2),
      transmissionTypes: new FormControl([]),
      numberOfDoors: new FormControl([]),
      bodyTypes: new FormControl([]),
      keyword: new FormControl(""),
      colours: new FormControl([]),
      isMiles: new FormControl(false),
      insuranceWriteOff: new FormControl(false),
      showNoIREPrice: new FormControl(false),
      showNoCosting: new FormControl(false),
      vatAdjustment: new FormControl("")
    });
  }

  async getFacets() {
    if (!this.loadFacets()) {
      const res = await this.apiService.getFacets();
      if (res?.success) {
        this.makes = res.data.makes;
        this.bodyTypes = res.data.bodyTypes;
        this.colours = res.data.colours;
        this.saveFacets();
      }
    }
  }

  private saveFacets(): void {
    this.storage.set("makes", this.makes);
    this.storage.set("bodyTypes", this.bodyTypes);
    this.storage.set("colours", this.colours);
  }

  private loadFacets(): boolean {
    this.makes = this.storage.get("makes");
    this.bodyTypes = this.storage.get("bodyTypes");
    this.colours = this.storage.get("colours");
    return Boolean(this.makes && this.bodyTypes && this.colours);
  }

}
