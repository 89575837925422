import {Component, OnInit} from '@angular/core';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatSort} from "@angular/material/sort";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {DecimalPipe, NgClass, NgIf} from "@angular/common";
import {ApiService} from "../../../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SourcePipe} from "../../../../pipes/source.pipe";
import {MatIcon} from "@angular/material/icon";

interface Source {
  source: string;
  total?: string;
  vrt?: string;
  priceEstIRE?: string;
  priceUK?: string;
  customsRate?: string;
  geoloc?: string;
  completeData?: string;
  status?: 'loading' | 'error' | 'valid'; // Add status property
}

@Component({
  selector: 'app-sources',
  standalone: true,
  imports: [
    MatProgressSpinner,
    MatSort,
    MatTable,
    NgIf,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatCellDef,
    MatHeaderCellDef,
    MatHeaderRow,
    MatRow,
    MatRowDef,
    MatHeaderRowDef,
    DecimalPipe,
    SourcePipe,
    MatIcon,
    NgClass
  ],
  templateUrl: './sources.component.html',
  styleUrl: './sources.component.scss'
})
export class SourcesComponent implements OnInit {
  protected sourcesData = new MatTableDataSource<Source>([]);
  protected displayedColumns = ['source','completeData','vrt','priceEstIRE','priceUK','customsRate','geoloc','total'];
  protected loading: boolean = false;
  protected sources = [
    "bca",
    "manheim",
    "astonbarclay",
    "autotrader"
  ];
  constructor(private apiService: ApiService, private snack: MatSnackBar) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadSources();
    }

  private async loadSources() {
    this.loading = true; // Start the loading spinner
    this.sourcesData.data = []; // Clear any existing data in the table

    const currentData: Source[] = []; // Local table data tracking

    for (let source of this.sources) {
      // Step 1: Add a "loading" row for the current source
      currentData.push({
        source,
        status: 'loading'
      });
      // Update the table with the new "loading" row
      this.sourcesData.data = [...currentData]; // Trigger change detection

      try {
        // Step 2: Fetch the data for the current source
        const s = await this.apiService.getSource(source);

        if (s?.success) {
          // Step 3: Update the loading row with the actual data
          const index = currentData.findIndex((row) => row.source === source);
          if (index !== -1) {
            // Replace the placeholder row with the actual data
            currentData[index] = { ...s.data, source };
            this.sourcesData.data = [...currentData]; // Trigger table update
          }
        }
      } catch (e) {
        console.error(`Error loading data for source ${source}:`, e);

        // Handle error: update the appropriate row to reflect the failure
        const index = currentData.findIndex((row) => row.source === source);
        if (index !== -1) {
          currentData[index] = {
            source,
            status: 'error' // Set row status to error
          };
          this.sourcesData.data = [...currentData]; // Trigger table update
        }
      }
    }

    this.loading = false; // Stop the loading spinner after all rows are processed
  }
}
