<h2 mat-dialog-title class="dialog-title">
  {{ data.isGeneral ? 'Adjust General VAT' : 'Adjust VAT For Vehicle' }}
</h2>
<mat-dialog-content>
  <div class="dialog-container">
    <!-- Radio buttons to select adjustment type -->
    <mat-radio-group [(ngModel)]="adjustmentType" class="adjustment-type">
      <mat-radio-button value="fixed">Fixed Amount</mat-radio-button>
      <mat-radio-button value="percentage">Percentage of Net UK Purchase Price</mat-radio-button>
    </mat-radio-group>

    <!-- Input field for fixed amount -->
    <mat-form-field appearance="fill" *ngIf="adjustmentType === 'fixed'" class="vat-input">
      <mat-label>Enter Fixed VAT Adjustment</mat-label>
      <input
        matInput
        [(ngModel)]="vatAdjustment"
        type="number"
        placeholder="Enter Fixed VAT Adjustment"
      />
    </mat-form-field>

    <!-- Input field for percentage -->
    <mat-form-field appearance="fill" *ngIf="adjustmentType === 'percentage'" class="vat-input">
      <mat-label>Enter VAT Percentage</mat-label>
      <input
        matInput
        [(ngModel)]="vatPercentage"
        type="number"
        placeholder="Enter VAT Percentage"
      />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="button-row">
  <button mat-raised-button  (click)="saveAdjustment(false)">Set for Vehicle</button>
  <button mat-raised-button (click)="saveAdjustment(true)">Set for All Vehicles</button>
  <button mat-raised-button (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>
