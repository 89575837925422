<button class="close-button" mat-icon-button (click)="closeFeedback()">
  <mat-icon>close</mat-icon>
</button>

<h2 mat-dialog-title>How did we do?</h2>
<mat-dialog-content>
  <p class="modal-text">Thank you for using {{CommerceService.prodName(data.service)}}. We would love to know how you like it:</p>

    <div class="feedback-icons">
      <!-- Thumbs down in red -->
      <mat-icon
        class="thumb-icon thumb-down"
        (click)="feedbackService.feedback(0, data.vrm, data.service); bug.report(CommerceService.prodName(data.service), data)"
        role="button">
        thumb_down
      </mat-icon>

      <!-- Thumbs up in green -->
      <mat-icon
        class="thumb-icon thumb-up"
        (click)="feedbackService.feedback(1, data.vrm, data.service)"
        role="button">
        thumb_up
      </mat-icon>
    </div>
</mat-dialog-content>
