import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VatAdjustmentService {
  private refreshDataSource = new Subject<void>();

  // Observable to notify when data should be refreshed
  refreshData$ = this.refreshDataSource.asObservable();

  // Trigger a data refresh
  triggerDataRefresh() {
    this.refreshDataSource.next();
  }
}
