import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogActions,
  MatDialogContent,
} from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms"; // Import FormsModule
import { MatButtonModule } from "@angular/material/button";
import { FcfColsPipe } from "../../../pipes/fcf-cols.pipe";

@Component({
  selector: "app-fcf-column-visibility-dialog",
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    FormsModule,
    MatButtonModule,
    FcfColsPipe,
  ],
  templateUrl: "./fcf-column-visibility-dialog.component.html",
  styleUrls: ["./fcf-column-visibility-dialog.component.scss"],
})
export class FcfColumnVisibilityDialogComponent {
  // Available columns with visibility status
  availableColumns: any[];

  constructor(
    private dialogRef: MatDialogRef<FcfColumnVisibilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { availableColumns: string[]; displayedColumns: string[] }
  ) {
    // Initialize availableColumns for the dialog with visibility status
    this.availableColumns = data.availableColumns.map(column => ({
      name: column,
      visible: data.displayedColumns.includes(column),
    }));
  }

  /**
   * Save the updated column visibility settings.
   */
  save(): void {
    // Get visible (selected) columns
    const selectedColumns = this.availableColumns
      .filter(column => column.visible)
      .map(column => column.name);

    // Calculate hidden columns
    const hiddenColumns = this.availableColumns
      .filter(column => !column.visible)
      .map(column => column.name);

    // Save hidden columns to localStorage
    localStorage.setItem("hiddenColumns", JSON.stringify(hiddenColumns));

    // Pass the selected columns back to the parent component
    this.dialogRef.close(selectedColumns);
  }

  /**
   * Close the dialog without saving changes.
   */
  close(): void {
    this.dialogRef.close(); // Close the dialog without making changes
  }

  /**
   * Show a specific column.
   * @param columnKey The key of the column to show.
   */
  showColumn(columnKey: string): void {
    const column = this.availableColumns.find(c => c.key === columnKey);
    if (column) {
      column.visible = true;
    }
  }

  /**
   * Hide a specific column.
   * @param columnKey The key of the column to hide.
   */
  hideColumn(columnKey: string): void {
    const column = this.availableColumns.find(c => c.key === columnKey);
    if (column) {
      column.visible = false;
    }
  }
}
