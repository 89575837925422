import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { CurrencyPipe, DecimalPipe, NgClass, NgIf, PercentPipe } from "@angular/common";
import { MatPaginator, MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatSort, MatSortModule, Sort } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialog } from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { formatRes } from "../../../../pipes/format-res.pipe";
import { CurrencyService } from "../../../../services/currency.service";
import { FcfService, SortCriteria, VRT } from "../../../../services/fcf.service";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { HeroBgService } from "../../../../services/hero-bg.service";
import { MatToolbar, MatToolbarRow } from "@angular/material/toolbar";
import { MatIcon } from "@angular/material/icon";
import { DisclaimerComponent } from "../../../../components/disclaimer/disclaimer.component";
import { Subscription } from "rxjs";
import { CarDetailsDialog } from "../../../../components/dialogs/dialog-car-details/dialog-car-details";
import { FcfFiltersComponent } from "../../../../components/dialogs/fcf-filters/fcf-filters.component";
import {
  MatSlideToggle,
  MatSlideToggleChange,
  MatSlideToggleModule,
} from "@angular/material/slide-toggle";
import { HouseMiscChargeService } from "../../../../services/house-misc-charge.service";
import { FcfColumnVisibilityDialogComponent } from "../../../../components/dialogs/fcf-column-visibility-dialog/fcf-column-visibility-dialog.component";
import { VatAdjustmentService } from "../../../../services/vat-adjustment.service";

@Component({
  selector: "app-vrt",
  standalone: true,
  imports: [
    MatTableModule,
    MatToolbarRow,
    MatPaginatorModule,
    NgIf,
    DecimalPipe,
    MatProgressSpinner,
    MatSortModule,
    formatRes,
    MatTooltipModule,
    NgClass,
    MatButton,
    ReactiveFormsModule,
    MatToolbar,
    MatIcon,
    DisclaimerComponent,
    CurrencyPipe,
    PercentPipe,
    MatSlideToggle,
    MatSlideToggleModule,
  ],
  templateUrl: "./fcf-home.component.html",
  styleUrl: "./fcf-home.component.scss",
})
export class FcfHomeComponent implements AfterViewInit, OnInit, OnDestroy {
  private dataRefreshSubscription: Subscription | undefined;
  public filtersFormGroup!: FormGroup;

  // Keep all available columns in the availableColumns array
  public availableColumns = [
    "make",
    "yearOfRegistration",
    "kms",
    "engineType",
    "priceGBP",
    "priceEurExVat",
    "vatAdjustment", // Will be hidden by default
    "vrtEuro",
    "vat",
    "customsDuty",
    "shippingEur",
    "houseMiscCharge", // Will be hidden by default
    "totalCosts",
    "priceEstExVat",
    "profit",
    "roi",
  ];

  // Columns that should be visible by default (removing vatAdjustment and houseMiscCharge)
  public displayedColumns = [
    "make",
    "yearOfRegistration",
    "kms",
    "engineType",
    "priceGBP",
    "priceEurExVat",
    "vrtEuro",
    "vat",
    "customsDuty",
    "shippingEur",
    "totalCosts",
    "priceEstExVat",
    "profit",
    "roi",
  ];

  public vrt = new MatTableDataSource<VRT>([]);
  public loading = true;
  @ViewChild("topPaginator") topPaginator: MatPaginator | undefined;
  @ViewChild("bottomPaginator") bottomPaginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild("modelsFilter") modelsFilter!: MatSelect;
  @ViewChild('showMiles') showMiles: MatSlideToggle | undefined;
  total = 0;

  protected errorMsg: string | undefined;

  protected readonly Number = Number;
  private curSortCriteria: SortCriteria = {
    active: "id",
    direction: "desc",
  };
  private submitSubscription: Subscription | undefined;
  private resetSubscription: Subscription | undefined;
  protected mobileView: boolean = false;

  constructor(
    public dialog: MatDialog,
    protected currency: CurrencyService,
    public fcfService: FcfService,
    private hero: HeroBgService,
    private vatAdjustmentService: VatAdjustmentService,
    private houseMiscChargeService: HouseMiscChargeService
  ) {
    this.filtersFormGroup = this.fcfService.initForm();
    if (window && window.innerWidth < 700) {
      this.mobileView = true;
      // Remove columns to accomodate mobile
      this.displayedColumns = ["make", "yearOfRegistration", "totalCosts", "profit"];
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from submitSubscription if it exists to prevent memory leaks
    this.submitSubscription?.unsubscribe();

    // Unsubscribe from resetSubscription if it exists to prevent memory leaks
    this.resetSubscription?.unsubscribe();

    // Check if dataRefreshSubscription is active before unsubscribing
    // This ensures that we clean up any ongoing subscriptions properly
    if (this.dataRefreshSubscription) {
      // Unsubscribe from dataRefreshSubscription to avoid memory leaks
      this.dataRefreshSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    // Initialize hidden columns if not already set
    if (!localStorage.getItem("hiddenColumns")) {
      const defaultHiddenColumns = ["vatAdjustment", "houseMiscCharge"];
      localStorage.setItem("hiddenColumns", JSON.stringify(defaultHiddenColumns));
    }

    // Get current hidden columns
    const hiddenColumns = JSON.parse(localStorage.getItem("hiddenColumns") || "[]");

    // Update displayed columns
    this.displayedColumns = this.availableColumns.filter(column => !hiddenColumns.includes(column));

    // Set the background image of the hero component with specified positioning
    this.hero.setBg("/assets/img/fast.jpg", "center center");

    // Subscribe to the refreshData$ observable from vatAdjustmentService
    // This will trigger a data refresh whenever there's a new emission from the observable
    this.dataRefreshSubscription = this.vatAdjustmentService.refreshData$.subscribe(() => {
      // Call getData method with current page size and index from topPaginator,
      // Defaulting to 50 for pageSize and 1 for pageIndex if not set
      this.getData(this.topPaginator?.pageSize || 50, this.topPaginator?.pageIndex || 1);
    });

    // Subscribe to the refreshData$ observable from houseMiscChargeService
    // This will trigger a data refresh whenever there's a new emission from the observable
    this.dataRefreshSubscription = this.houseMiscChargeService.refreshData$.subscribe(() => {
      // Call getData method with current page size and index from topPaginator,
      // Defaulting to 50 for pageSize and 1 for pageIndex if not set
      this.getData(this.topPaginator?.pageSize || 50, this.topPaginator?.pageIndex || 1);
    });
  }

  async ngAfterViewInit() {
    // Check if topPaginator and sort objects are defined before proceeding
    if (this.topPaginator && this.sort) {
      // Assign the sort object to the vrt component for sorting functionality
      this.vrt.sort = this.sort;
      // Uncomment the line below if you want to set a paginator for vrt in the future
      // this.vrt.paginator = this.paginator;
    }

    // Fetch data using getData method with the current page size from topPaginator
    // Defaulting to page index 1, handling any errors that might occur during fetch
    await this.getData(this.topPaginator?.pageSize, 1).catch(e => {
      // Set an error message if data fetching fails and log the error to the console
      this.errorMsg = `Could not fetch data due to an error: ${e.message}`;
      console.error(e);
    });
    // Subscribe to toggle value changes
    this.showMiles?.toggleChange.subscribe(() => {
      this.getData(); // Refetch data when toggle is changed
    });
    // Call getFacets method from fcfService to retrieve facet data asynchronously
    await this.fcfService.getFacets();
  }

  openFilterDialog() {
    // Open a dialog for applying filters, using FcfFiltersComponent
    const filterDialog = this.dialog.open(FcfFiltersComponent, {
      // Allow the dialog to close when navigation occurs
      closeOnNavigation: true,
      // Pass data to the dialog component, including the mode and the current form group
      data: {
        mode: "fcf",
        filtersFormGroup: this.filtersFormGroup,
      },
      // Assign an ID to the dialog for identification purposes
      id: "fcf-filters",
    });

    // Subscribe to the submit event from the filter dialog component instance
    this.submitSubscription = filterDialog.componentInstance.submit.subscribe(async () => {
      // Close the filter dialog when the submit event is triggered
      filterDialog.close();

      // Check if topPaginator is defined before attempting to set the page index
      if (this.topPaginator) {
        // Set paginator page index to 0 (1st page)
        this.topPaginator.pageIndex = 0;
      }

      // Fetch data again based on the updated filters and reset to the first page
      await this.getData(this.topPaginator?.pageSize, 1).catch(e => {
        // Log any errors that occur during data fetching
        console.error(e);
      });
    });

    // Subscribe to the reset event from the filter dialog component instance
    this.resetSubscription = filterDialog.componentInstance.reset.subscribe(() => {
      // Initialize the filters form group back to its original state
      this.filtersFormGroup = this.fcfService.initForm();
      // Update the dialog's data with the new filters form group
      filterDialog.componentInstance.data.filtersFormGroup = this.filtersFormGroup;

      // Fetch data again based on the updated filters without error handling
      this.getData(this.topPaginator?.pageSize, 1).catch(e => {});
    });
  }

  openColumnVisibilityDialog(): void {
    const hiddenColumns = JSON.parse(localStorage.getItem("hiddenColumns") || "[]");

    const dialogRef = this.dialog.open(FcfColumnVisibilityDialogComponent, {
      width: "700px",
      data: {
        availableColumns: this.availableColumns, // Pass the updated string array
        displayedColumns: this.displayedColumns, // Pass currently displayed columns
      },
    });

    // Update displayedColumns when dialog is closed
    dialogRef.afterClosed().subscribe((selectedColumns: string[]) => {
      if (selectedColumns) {
        this.displayedColumns = selectedColumns; // Update displayed columns based on user selection
      }
    });
  }

  openDialog(data: VRT) {
    // Open a dialog for displaying car details using CarDetailsDialog component
    this.dialog.open(CarDetailsDialog, {
      // Pass the car data to the dialog component
      data: data,
      // Assign an ID to the dialog for identification purposes
      id: "car-details",
    });
  }

  announceSortChange($event: Sort) {
    // Uncomment the line below to log sorting changes for debugging purposes
    // console.log("Sorting:", $event);

    // Check if the top paginator is defined (exists)
    if (this.topPaginator) {
      // Reset the paginator to the first page whenever a new sort is applied
      this.topPaginator.pageIndex = 0;

      // Update the current sorting criteria with the new values from the event
      this.curSortCriteria = {
        active: $event.active, // The column currently being sorted
        direction: $event.direction, // The direction of sorting: 'asc' or 'desc'
      };

      // Fetch data again with the new sort criteria and reset to the first page
      this.getData(this.topPaginator.pageSize, 1);
    }
  }

  handlePageEvent(event: PageEvent) {
    // Check if the top paginator is defined (exists)
    if (this.topPaginator) {
      // Update the current page index of the top paginator based on the event
      this.topPaginator.pageIndex = event.pageIndex;

      // Update the page size of the top paginator according to the event
      this.topPaginator.pageSize = event.pageSize;

      // Uncomment the line below to set the total number of items in the top paginator
      // this.paginator.length = this.fcfService.total;
    }

    // Check if the bottom paginator is defined (exists)
    if (this.bottomPaginator) {
      // Update the current page index of the bottom paginator based on the event
      this.bottomPaginator.pageIndex = event.pageIndex;

      // Update the page size of the bottom paginator according to the event
      this.bottomPaginator.pageSize = event.pageSize;

      // Uncomment the line below to set the total number of items in the bottom paginator
      // this.paginator.length = this.fcfService.total;
    }

    // Fetch new data based on the updated page size and page index (adding 1 for zero-based indexing)
    this.getData(event.pageSize, event.pageIndex + 1);
  }

  async getData(pageSize = 50, pageIndex = 1): Promise<void> {
    // Get hidden columns from localStorage
    const hiddenColumns = JSON.parse(localStorage.getItem("hiddenColumns") || "[]");

    // Update displayed columns
    this.displayedColumns = this.availableColumns.filter(column => !hiddenColumns.includes(column));

    // Set loading state to true to indicate that data fetching is in progress
    this.loading = true;

    try {
      // Fetch raw data from the backend using specified parameters: page size, page index, current sort criteria, and filter values
      const rawData = await this.fcfService.get(
        pageSize,
        pageIndex,
        this.curSortCriteria,
        this.filtersFormGroup.value
      );

      // Check if mileage toggle is enabled; retrieve value from form group
      const isMiles = this.filtersFormGroup.get("isMiles")?.value;

      // Retrieve VAT adjustment filter value from the form group
      const vatAdjustmentFilter = this.filtersFormGroup.get("vatAdjustment")?.value;
      const vatAdjustmentFilterNumber = vatAdjustmentFilter ? Number(vatAdjustmentFilter) : null;

      // Get the general VAT adjustment value and percentage from localStorage
      const generalVatAdjustment = Number(localStorage.getItem("vatAdjustment_general") || "0");
      const generalVatPercentage = Number(
        localStorage.getItem("vatAdjustment_general_percentage") || "0"
      );

      // Get the general house miscellaneous charge value from localStorage; default to 0 if not found
      const generalHouseMiscCharge = Number(localStorage.getItem("houseMiscCharge_general") || "0");

      // Process the fetched raw data: transform the structure of each item
      this.vrt.data = rawData
        .map(item => {
          // Safely parse essential numeric properties from the item
          const totalCosts = Number(item.totalCosts ?? 0); // Total costs of the item
          const profit = Number(item.profit ?? 0); // Profit associated with the item
          const mileageKm = Number(item.kms ?? 0); // Mileage in kilometers
          const priceEurExVat = Number(item.priceEurExVat ?? 0); // Est. Purchase price UK

          // Convert mileage based on user preferences (miles or kilometers)
          const mileage = isMiles ? mileageKm * 0.621371 : mileageKm;

          // Determine individual VAT adjustment for the item
          const individualVatAdjustment = Number(
            localStorage.getItem(`vatAdjustment_${item.id}`) || generalVatAdjustment
          );

          // Determine individual VAT percentage adjustment for the item
          const individualVatPercentage = Number(
            localStorage.getItem(`vatAdjustment_${item.id}_percentage`) || generalVatPercentage
          );

          // Use percentage-based VAT adjustment if applicable
          const finalVatAdjustment =
            individualVatPercentage > 0 && priceEurExVat > 0
              ? (individualVatPercentage / 100) * priceEurExVat
              : individualVatAdjustment;

          // Retrieve house/misc charge for the item or use the general charge as a fallback
          const houseMiscCharge = Number(
            localStorage.getItem(`houseMiscCharge_${item.id}`) ||
              localStorage.getItem(`houseMiscCharge_${item.make}_${item.model}`) ||
              generalHouseMiscCharge
          );

          // Only calculate profit and ROI if priceEstExVat is set
          let adjustedProfit = null;
          let updatedRoi = null;
          if (item.priceEstExVat) {
            const totalAdjustments = finalVatAdjustment + houseMiscCharge;
            adjustedProfit = profit - totalAdjustments; // Include house/misc charge in profit calculation
            updatedRoi = totalCosts > 0 ? adjustedProfit / (totalCosts + totalAdjustments) : 0; // Include house/misc charge in ROI calculation
          }

          // Calculate updated total costs including the VAT adjustment and house/misc charge
          let updatedTotalCosts;
          if (item.totalCosts) {
            updatedTotalCosts = totalCosts + finalVatAdjustment + houseMiscCharge;
          }

          // Return a new object containing the original item properties along with updated and formatted values
          return {
            ...item, // Spread other existing item properties
            vatAdjustment: finalVatAdjustment, // Format VAT adjustment as a string with two decimal places
            totalCosts: updatedTotalCosts?.toString() ?? item.totalCosts, // Format total costs
            profit: adjustedProfit?.toString() ?? null, // Format profit
            roi: updatedRoi?.toString() ?? null, // Format ROI as a percentage
            mileage,
            houseMiscCharge: Math.round(houseMiscCharge), // Format house/misc charge
          };
        })
        .filter(item => {
          // Apply VAT adjustment filter if a filter value exists
          if (vatAdjustmentFilterNumber !== null) {
            return Number(item.vatAdjustment) === vatAdjustmentFilterNumber; // Filter items based on VAT adjustment
          }
          return true; // Include all items if no filter is applied
        });

      // Update the total count of data items retrieved
      this.total = this.fcfService.total;
    } catch (error: unknown) {
      // Handle errors; assert the error type to provide meaningful feedback
      if (error instanceof Error) {
        this.errorMsg = `Failed to fetch data: ${error.message}`;
      } else {
        this.errorMsg = `Failed to fetch data: An unknown error occurred`;
      }
      console.error(error); // Log error details for debugging
    } finally {
      // Reset loading state to false regardless of whether the fetch was successful or not
      this.loading = false;
    }
  }
  get partialCostingsToggled(): boolean {
    return !Boolean(
      this.filtersFormGroup.get("showNoCosting")?.value |
      this.filtersFormGroup.get("showNoIREPrice")?.value
    );
  }

  async toggleCosting($event: MatSlideToggleChange) {
    if (this.filtersFormGroup) {
      this.filtersFormGroup.get("showNoCosting")?.setValue(!$event.checked);
      this.filtersFormGroup.get("showNoIREPrice")?.setValue(!$event.checked);
      await this.getData(this.topPaginator?.pageSize, 1);
    }
  }
}
