<section class="header">
  <h1>We Help You Find Your Ideal Next Purchase.</h1>
<!--  <h2>We Got You.</h2>-->
</section>
<section class="pageContent">
  <!--  @if (!init) {-->
  <!--    <app-prod-details singleProduct="fcf" [showPriceTag]="false" (load)="load()"></app-prod-details>-->
  <!--  } @else {-->

  <h3>List of vehicles currently on the market</h3>

  <div class="fcfContainer">

    <button mat-raised-button color="primary" (click)="openFilterDialog()">Toggle filters <mat-icon  >tune</mat-icon></button>

    <mat-toolbar>
      <mat-toolbar-row>
        <span class="spacer"></span>
        <mat-progress-spinner mode="indeterminate" matTooltip="The vehicles are still loading into the table"
                              diameter="25" *ngIf="fcfService.loading"></mat-progress-spinner>

        <div [formGroup]="filtersFormGroup" class="toggleButton">
          <mat-slide-toggle formControlName="isMiles" #showMiles>
            Show mileage in miles
          </mat-slide-toggle>
        </div>
        <mat-slide-toggle (change)="toggleCosting($event)" [checked]="partialCostingsToggled">Only show cars with complete data
        </mat-slide-toggle>
        <div class="button-container">
          <button mat-raised-button color="primary" (click)="openFilterDialog()">
            Toggle filters <mat-icon>tune</mat-icon>
          </button>

          <button mat-raised-button (click)="openColumnVisibilityDialog()">
            <mat-icon>table_chart</mat-icon> Toggle Columns
          </button>
        </div>

        <mat-paginator #topPaginator [pageSize]="topPaginator?.pageSize ?? 20"
          [pageSizeOptions]="[20, 50, 100, 250, 500]" [length]="total" aria-label="Select page"
          (page)="handlePageEvent($event)" showFirstLastButtons [class.disabled]="loading">
        </mat-paginator>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-progress-spinner *ngIf="loading" class="table-spinner" mode="indeterminate"
      [diameter]="50"></mat-progress-spinner>
    <table mat-table [dataSource]="vrt" matSort (matSortChange)="announceSortChange($event)" [class.disabled]="loading">
      <ng-container matColumnDef="make">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Make and Model</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span>{{ element.make }} {{ element.model }}</span>
            <br />
            <small class="version-data" matTooltip="{{ element.versionData }}">
              {{ element.versionData?.substring(0, 100) }}{{ element.versionData?.length > 100 ? '...' : '' }}
            </small>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="yearOfRegistration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Year</th>
        <td mat-cell *matCellDef="let element">{{ element.yearOfRegistration }}</td>
      </ng-container>
      <ng-container matColumnDef="kms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mileage</th>
        <td mat-cell *matCellDef="let element">
          {{ element.mileage | number: "1.0-0" }} {{ showMiles.checked ? "miles" : "km" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="engineType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Engine Type</th>
        <td mat-cell *matCellDef="let element">
          {{ (element | formatRes)["Engine type / fuel"] }}
        </td>
      </ng-container>
      <ng-container matColumnDef="priceGBP">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Est. Purchase Price UK -Incl. VAT
          <mat-icon matTooltip="The UK purchase price including VAT, displayed in GBP." class="info-icon">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.priceGBP | currency: "GBP" : "symbol" : "1.0-0" : "en"
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="priceEurExVat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Est. Purchase Price UK - Net VAT
          <mat-icon [matTooltip]="
              'Estimate Purchase Price based on Advertised Price or Clean Cap excluding VAT. Currency Conversion Rate EUR/GBP ' + (currency.rates['gbp'] | number: '1.3-3': 'en')
            " class="info-icon">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.priceEurExVat | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="vatAdjustment">
        <th mat-header-cell *matHeaderCellDef>
          Vat Adjustment &/or Delivery
          <mat-icon matTooltip="Your Adjustments for VAT Brokering and/or Delivery Charges" class="info-icon">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.vatAdjustment | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="priceEstExVat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Est. Sales Price IE
          <mat-icon matTooltip="Estimate based on similar Make and Model, Version, Year and Mileage. Ex. VAT."
            class="info-icon">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          {{
          element.priceEstExVat
          ? (element.priceEstExVat | currency: "EUR" : "symbol" : "1.0-0" : "en")
          : ""
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="vrtEuro">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>VRT</th>
        <td mat-cell *matCellDef="let element">
          {{ element.vrtEuro | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="vat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>VAT</th>
        <td mat-cell *matCellDef="let element">
          {{ element.vat | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="customsDuty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customs Duty</th>
        <td mat-cell *matCellDef="let element">
          {{ element.customsDuty | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="shippingEur">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Est. Shipping</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.shippingEur > 0
          ? (element.shippingEur | currency: "EUR" : "symbol" : "1.0-0" : "en")
          : "n/a"
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="houseMiscCharge">
        <th mat-header-cell *matHeaderCellDef>
          House/Misc Charge
          <mat-icon matTooltip="Miscellaneous charges for the house or other expenses" class="info-icon">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.houseMiscCharge | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="profit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Est. Profit</th>
        <td mat-cell *matCellDef="let element">
          {{ element.profit | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalCosts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="highlight-header">Total Cost to Import</th>
        <td mat-cell *matCellDef="let element" class="highlight-cell">
          {{ element.totalCosts | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="roi">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          ROI
          <mat-icon matTooltip="Return On Investment ie Est. Profit as a Percentage of Total Cost to Import" class="info-icon">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.roi | percent: "1.0-0" : "en" }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns" (click)="openDialog(element)" class="clickable"
        [ngClass]="{ masked: element.masked }"></tr>
    </table>

    <mat-paginator #bottomPaginator [pageSize]="topPaginator?.pageSize ?? 20"
      [pageSizeOptions]="[20, 50, 100, 250, 500]" [length]="total" aria-label="Select page"
      (page)="handlePageEvent($event)" showFirstLastButtons [class.disabled]="loading">
    </mat-paginator>

    <p class="error" *ngIf="errorMsg">{{ errorMsg }}</p>
    <app-disclaimer></app-disclaimer>
  </div>
  <!--  }-->
</section>
<!--<section class="valueProp">-->
<!--  <h2>What You Get From Find Cars Faster</h2>-->
<!--  <div class="valueProps">-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/relax.svg" width="16" height="16" alt="Time">-->
<!--      <p>Time. Time. Time. Time.</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/customer-service.svg" width="16" height="16" alt="Ideal cars">-->
<!--      <p>The Ideal Cars presented to You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/money.svg" width="16" height="16" alt="Money">-->
<!--      <p>Projected Profit</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div class="img">ROI</div>-->
<!--      <p>Projected ROI</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/money.svg" width="16" height="16" alt="Money">-->
<!--      <p>Total Cost to Land</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/stopwatch.svg" width="16" height="16" alt="Stopwatch">-->
<!--      <p>Average Time to Sell</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/zoom.svg" width="16" height="16" alt="Magnifying glass">-->
<!--      <p>‘Funny Stuff’ Flagged (Before History Checks)</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div class="img">VRT</div>-->
<!--      <p>VRT Calculated for You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div class="img">VAT</div>-->
<!--      <p>VAT Calculated for You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div class="img">NOx</div>-->
<!--      <p>NOx Calculated for You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/currencies.svg" width="16" height="16" alt="Currency">-->
<!--      <p>Forex Calculated for You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/ship.svg" width="16" height="16" alt="Ship">-->
<!--      <p>Shipping Calculated for You</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/cars.svg" width="16" height="16" alt="Cars">-->
<!--      <p>Browse cars using dealer specific functionality</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/zoom.svg" width="16" height="16" alt="Search">-->
<!--      <p>Search based on your criteria</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/alert.svg" width="16" height="16" alt="Alert">-->
<!--      <p>Alerts sent for your ideal cars</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img ngSrc="/assets/icons/handshake.svg" width="16" height="16" alt="Alert">-->
<!--      <p>Importing Cars Made Easy</p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <button class="c2aLarge" mat-raised-button role="link" (click)="getStarted()">Get Started</button>-->
<!--</section>-->
<!--<section class="stats">-->
<!--  <h2>Why You Need TheCarSite</h2>-->
<!--  <app-statbits sort="fcf"></app-statbits>-->
<!--  <button class="c2aLarge" mat-raised-button role="link" color="primary" (click)="getStarted()">Get Started</button>-->
<!--</section>-->
