import {Pipe, PipeTransform} from "@angular/core";
import {formatCurrency, formatNumber} from "@angular/common";
import {EngineTypePipe} from "./engine-type.pipe";
import {TransmissionTypePipe} from "./transmission-type.pipe";
import {BodyTypePipe} from "./body-type.pipe";

@Pipe({
  standalone: true, name: "formatRes"
})
export class formatRes implements PipeTransform  {
  transform(res: { [key: string]: any }, showDetails = false): { [key: string]: string | number | boolean } {
    let formattedRes: any = {};
    let keys = Object.keys(res);
    for (let key of keys) {
      let value = res[key];
      // console.log(key, value);
      if (!value && value !== 0) {
        continue;
      }
      // Skip array values
      if (Array.isArray(value)) {
        continue;
      }
      const maskFields = showDetails ?
        ["id","vin","source","loc","unmodifiedVRTAmount","vehicle_id","ts","priceEst","experian_id","place_id","priceGBP_exvat","profit","exchange_rate","priceGBP","updated_ts","monthOfRegistration","astonbarclay_sync_ts","astonbarclay_id","autotrader_sync_ts","autotrader_id","manheim_sync_ts","manheim_id","attemptedVrt_ts","blockingSelect","markedForExport", "yearOfRegistration"] :
        ["id","vin","source","loc","unmodifiedVRTAmount","vehicle_id","ts","priceEst","experian_id","place_id","priceEstExVat","priceGBP_exvat","profit","exchange_rate","customsDuty","priceGBP","vrtEuro","updated_ts","monthOfRegistration","registrationNumber","astonbarclay_sync_ts","astonbarclay_id","autotrader_sync_ts","autotrader_id","manheim_sync_ts","manheim_id","attemptedVrt_ts","blockingSelect","markedForExport", "yearOfRegistration"];
      if (maskFields.indexOf(key) > -1) {
        // We do not want to output this
        continue;
      }
      switch (key) {
        case "euClassification":
          key = "EU classification";
          break;
        case "firstregisteredInJapan":
          key = "First registered in Japan?";
          value = value ? "Yes" : "No";
          break;
        case "transmissionType":
          key = "Transmission";
          value = TransmissionTypePipe.lookup(value);
          break;
        case "numberOfDoors":
          key = "Number of doors";
          break;
        case "engineType":
          key = "Engine type / fuel";
          value = EngineTypePipe.lookup(value);
          break;
        case "bodyType":
          key = "Body type";
          value = BodyTypePipe.lookup(value);
          break;
        case "engineCapacity":
          key = "Engine size";
          value = `${value} cc`;
          break;
        case "mileage":
          const miles = formatNumber(Number(value),"en")
          key = "Mileage (miles)";
          value = `${miles} miles`;
          break;
        case "kms":
          const kms = formatNumber(Number(value),"en")
          key = "Mileage (km)";
          value = `${kms} km`;
          break;
        case "yearOfRegistration":
          key = "Year of registration";
          break;
        case "monthOfFirstRegistration":
          key = "Date of registration";
          break;
        case "yearOfManufacture":
          key = "Year of Manufacture";
          break;
        case "dateOfLastV5CIssued":
          key = "Last V5C issued";
          break;
        case "taxDueDate":
          key = "Tax due";
          break;
        case "taxStatus":
          key = "Tax status";
          break;
        case "motStatus":
          key = "MOT status";
          break;
        case "motExpiryDate":
          key = "MOT expires";
          break;
        case "make":
          key = "Make";
          value = String(value).toLowerCase();
          break;
        // Details
        case "nox":
          key = "NOx emissions";
          value = `${value} g/km`;
          break;
        case "co2Emissions":
          key = "CO2 emissions";
          value = `${value} g/km`;
          break;
        case "versionData":
          key = "Description";
          break;
        case "co2Charges":
          key = "VRT CO2 charges";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "deprCode":
          key = "Depreciation code";
          break;
        case "deprRate":
          key = "Depreciation rate";
          const deprRate = Number(value) * 100;
          value = `${formatNumber(deprRate,"en","1.0-0")} %`;
          break;
        case "mileageRed":
          key = "OMSP Mileage reduction";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "monthlyAdjustment":
          key = "OMSP adjustment for month";
          const val = ((1 - value)*100);
          value = val < 0 ? '-' : '+' + `${formatNumber(val,"en")} %`;
          break;
        case "noxCharge":
          key = "VRT NOx charge";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "omsp":
          key = "VRT original OMSP";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "omsp_current":
          key = "VRT current OMSP";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "priceEst":
          key = "Est. sales price IE";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "priceEstExVat":
          key = "Est. sales price IE (ex. VAT)";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "vat":
          key = "VAT payable";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "statCode":
          key = "VRT statistical code";
          break;
        case "unmodifiedVRTAmount":
          key = "CO2 charge rate";
          value = `${formatNumber(Number(value) * 100,"en","1.0-0")} %`;
          break;
        case "vrtEuro":
          key = "VRT charge";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "wltpco2":
          key = "WLTP CO2 emissions";
          value = `${value} mg/km`;
          break;
        case "priceGBP":
          key = "Est. purchase price UK";
          value = `${formatCurrency(Number(value),"en","£","GBP","1.0-0")}`;
          break;
        case "priceEurExVat":
          key = "Est. purchase price UK (ex. VAT)";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
          case "vatAdjustment":
          key = "Vat Adjustment &/or Delivery";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "customsRate":
          key = "Customs duty rate";
          value = `${formatNumber(Number(value) * 100,"en","1.0-0")} %`;
          break;
        case "customsDuty":
          key = "Customs duty";
          value = `${formatCurrency(Number(value),"en","€","EUR","1.0-0")}`;
          break;
        case "place_name":
          key = "Vehicle location";
          break;
        case "roi":
          key = "Est. Return on Investment";
          value = `${formatNumber(Number(value) * 100,"en","1.0-0")} %`;
          break;
        case "model":
          key = "Model";
          value = String(value).toLowerCase();
          break;
        case "model_tfl":
          key = "Model version";
          value = String(value).toLowerCase();
          break;
        case "wheelplan":
          key = "Wheelplan";
          break;
        case "colour":
          key = "Colour";
          break;
        case "revenueWeight":
          key = "Revenue weight";
          value = `${value} kg`
          break;
        case "euroStatus":
          key = "Euro emission standard";
          break;
        case "version":
          key = "ROS version designation"
          break;
        case "vin":
          key = "VIN";
          break;
        case "registrationNumber":
          key = "License Plate";
          break;
        case "countryOfOrigin":
          key = "Country of origin";
          break;
        case "seats":
          key = "Seating capacity";
          break;
      }
      formattedRes[key] = value;
    }
    return formattedRes;
  }
}
